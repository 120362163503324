import  { useState } from 'react'

export const useLayout = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleMenu = () => {
        setIsMenuOpen(prev => !prev)
    }
  return {
    isMenuOpen,
    handleMenu
  }
}
