import TitleHeading from "../TitleHeading"
import { useTitleHeading } from "../TitleHeading/useTitleHeading"
import WhatWeDoCreate from "./WhatWeDoCreate"


const WhatWeDo = () => {
    const {isListing, setIsListing} = useTitleHeading()
    return (
      <div className={`w-100`}>
              <div className={` `}>
                  <div className={`row`}>
                      <TitleHeading title={`What we do`} setIsListing={setIsListing} isListing={isListing} hideBtn/>
                      
                      
                      <WhatWeDoCreate/>
                     
                  </div>
              </div>
          </div>
    )
    
}

export default WhatWeDo