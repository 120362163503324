
import { useNavigate } from "react-router-dom"
import TitleHeading from "../TitleHeading"
import { useTitleHeading } from "../TitleHeading/useTitleHeading"
import BannerCreate from "./bannerCreate"
import BannerList from "./bannerList"

const Banner = () => {
    const url_data = '/admin/banner'
    const { isListing, setIsListing } = useTitleHeading();
    const navigate = useNavigate();

    return (
        <div className={`w-100`}>
            <div className={` `}>
                <div className={`row`}>
                    <TitleHeading title={`Banner`} setIsListing={() => {
                        setIsListing(!isListing)
                        navigate(`/admin/banner`)
                    }} isListing={!isListing} url={url_data} />

                    {
                        !isListing ?
                            <BannerList handleView={setIsListing} isListing /> :
                            <BannerCreate handleView={setIsListing} isListing />
                    }

                </div>
            </div>
        </div>
    )

}

export default Banner