import React from 'react'
import { Button, Table } from 'react-bootstrap';
import { useTestimonials } from './useTestimonials';



const TestimonialsList = ({handleView}) => {
  const { data, handleDelete, handleEdit } = useTestimonials({handleView});
   
  return (
    <div>
        <Table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {
           data &&  data.map((item, index) => {
                return(
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>
                          <Button type="button" variant="primary" className={`text-white`} onClick={() => handleEdit(item._id)}>Edit</Button>
                          <Button type="button" variant="primary ms-2" className={`text-white`} onClick={() => handleDelete(item._id)}>Delete</Button>
                          </td>
                    </tr>
                )
            })
        }
      </tbody>
    </Table>
    </div>
  )
}



export default TestimonialsList
