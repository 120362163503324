import React from 'react'
import iconSet from "./selection.json";
import IcomoonReact from "icomoon-react";

function Icon({icon, size, color, className, style}) {
    const props = {icon, size, color, className, style}
    return (
        <IcomoonReact iconSet={iconSet} {...props}/>
    )
}

export default Icon
