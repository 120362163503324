import { Link } from 'react-router-dom'
import style from './header.module.scss'
import { useHeader } from './useHeader'
import { useAuth } from '../Auth/useAuth'
const Header = () => {
    const { open, setOpen } = useHeader()
    const { handleLogout } = useAuth();
    const openClass = open ? style.open : '';
    return (
        <header className={`${style.header} ${openClass}`}>
            <div className={`${style.header_inner}`}>
                <div className={`${style.logo}`}>
                    GURUVAYOOR SHOPPEE
                </div>
                <div className={`${style.menu}`}>
                    <div className={`${style.menu_sub_item_parent} mt-1`}>
                        Content Management
                    </div>
                    <div className={`${style.menu_inner}`}>
                        <div className={`${style.menu_items}`}>

                            {
                                DATA.map((item, index) => {
                                    return (
                                        <div className={`${style.menu_item}`} key={item.id}>
                                            <Link to={`${item.url}`} className={style.link}>
                                                {item.title}
                                            </Link>
                                        </div>
                                    )
                                })
                            }



                        </div>
                    </div>

                    <div className={`${style.menu_sub_item_parent} mt-4`}>
                        Product Management
                    </div>
                    <div className={`${style.menu_inner}`}>
                        <div className={`${style.menu_items}`}>

                            {
                                DATA1.map((item, index) => {
                                    return (
                                        <div className={`${style.menu_item}`} key={item.id}>
                                            <Link to={`${item.url}`} className={style.link}>
                                                {item.title}
                                            </Link>
                                        </div>
                                    )
                                })
                            }



                        </div>
                    </div>
                </div>
            </div>
            <div className={`${style.header_icon}`} onClick={() => setOpen(!open)}></div>

            <div>
                <button className={`${style.link_logout} mt-5`} onClick={handleLogout}>Logout</button>
            </div>
        </header>
    )
}

export default Header


const DATA = [
    // {
    //     id : 1,
    //     title : "Home",
    //     url : '/admin/dashboard'
    // },
    // {
    //     id : 2,
    //     title : "Products",
    //     url : '/admin/product'
    // },
    // {

    //     id : 2.1,
    //     title : "Product Category",
    //     url : '/admin/category'
    // },

    {
        id: 1,
        title: "Banner",
        url: '/admin/banner'
    },
    {
        id: 2,
        title: "Connect Us",
        url: '/admin/connect-media'
    },
    {
        id: 3,
        title: "What we do ",
        url: '/admin/what-we-do'
    },
    {
        id: 3,
        title: "gallery",
        url: '/admin/gallery'
    }, {
        id: 4,
        title: "testimonials",
        url: '/admin/testimonials'
    }
]



const DATA1 = [
    {
        id: 1,
        title: "Home",
        url: '/admin/dashboard'
    },
    {
        id: 2,
        title: "Products",
        url: '/admin/product'
    }
]