import React from 'react'
import style from './heroSection.module.scss'
import Banner from '../Banner'
import FeaturedProduct from '../FeaturedProduct'
function HeroSection(props) {
  return (
    <div className={style.root}>
      <div className="container">
        <div className="row g-1 g-sm-2">
          <div className="col-12 col-lg-9"><Banner data={props.bannerData} /></div>
          <div className="col-12 col-lg-3"><FeaturedProduct data={props.featured} /></div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection