import axios from "axios";
import { useEffect, useState } from "react"
import { BASE_URL } from "../../Config/api";

export const useHome = () => {
  const [data, setData] = useState(null);
  const [newProduct, setNewProduct] = useState(null);
  const [featuredProduct, setFeaturedProduct] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [testimonials, setTestimonials] = useState(null);
  useEffect(() => {
    axios.get(`${BASE_URL}/home-page`).then((response) => {
      setData(response.data[0]);
    });
  }, []);

  useEffect(() => {
    axios.get(`${BASE_URL}/new-products`).then((response) => {
      setNewProduct(response.data);
    });

  }, []);


  useEffect(() => {
    axios.get(`${BASE_URL}/new-products?featured=1`).then((response) => {
      setFeaturedProduct(response.data);
    });

  }, []);


  useEffect(() => {
    axios.get(`${BASE_URL}/testimonials?limit=4`).then((response) => {
      setTestimonials(response.data);
    });
  }, []);



  useEffect(() => {
    axios.get(`${BASE_URL}/gallery?limit=4`).then((response) => {
      setGallery(response.data);
    });
  }, []);







  return {
    data,
    newProduct,
    testimonials,
    featuredProduct,
    gallery
  }
}