import React from 'react'
import { Link } from 'react-router-dom'
import style from './featuredProduct.module.scss'
import { BASE_URL } from '../../Config/api';
function FeaturedProduct({ data }) {

  console.log(data?.length || '', 'data');
  return (
    <div className={style.root}>
      {

        <div className={`${style.wrapper}`}>
          <div className={`${style.image}`}>
            <div className={`${style.featured_product}`}>{`featured Product`}</div>
            {data && data?.length > 0 && <img loading="lazy" src={`${BASE_URL}/uploads/${data[0]?.image[0]?.filename || null}`} alt="Featured" />}

          </div>
          <div className={`${style.text_wrapper}`}>
            <div className={`${style.detail_wrapper}`}>
              {data && data?.length > 0 && <h5 className={`${style.text} ttl-2`}>{data[0]?.title || ''}</h5>}
              {data && data?.length > 0 && <h6 className={`${style.price} ttl-2`}>{data[0]?.price || ''}</h6>}

            </div>
            <div className={`${style.link_wrapper}`}>
              {data && data?.length > 0 && <Link to={`/product/${data[0]?.url || '/'}`} className={`custom_btn primary`}>View Product</Link>}


            </div>


          </div>
        </div>
      }





    </div>
  )
}

export default FeaturedProduct