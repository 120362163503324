import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Layout from './components/Layout';
import Products from './components/Products';
import ProductDetail from './components/ProductDetail';
import ProductsAdmin from './components/Admin/Products';
import AdminLayout from './components/Admin/AdminLayout';
import Dashboard from './components/Admin/Dashboard';
import Connect from './components/Admin/Connect';
import Banner from './components/Admin/Banner';
import Gallery from './components/Admin/Gallery';
import Testimonials from './components/Admin/Testimonials';
import WhatWeDo from './components/Admin/WhatWeDo';
import HomePage from './components/Admin/homePage';
import Auth from './components/Admin/Auth';
import ContentManagement from './components/ContentManagement';
import Category from './components/Admin/Category';
import AboutUs from './components/AboutUs';
import ScrollToTop from './components/ScrollToTop/scrollTotop';
import EmptyPage from './components/EmptyPage';
import GalleryPage from './components/GalleryPage/galleryPage';
import ContactUs from './components/Contactus/contactUs';
const AppRoute = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>


        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='products' element={<Products />} />
          <Route path='gallery' element={<GalleryPage />} />
          <Route path='product/:name' element={<ProductDetail />} />
          <Route path='about-us' element={<AboutUs />} />
          <Route path="/404" element={<EmptyPage />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="*" element={<Navigate to="/404" />} />
        </Route>
        <Route path='/admin' element={<AdminLayout />} >
          <Route index element={<Auth />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="product" element={<ProductsAdmin />} />
          <Route path="product/:id" element={<ProductsAdmin />} />
          <Route path="connect-media" element={<Connect />} />
          <Route path="banner" element={<Banner />} />
          <Route path="banner/:id" element={<Banner />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="gallery/:id" element={<Gallery />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="testimonials/:id" element={<Testimonials />} />
          <Route path="what-we-do" element={<WhatWeDo />} />
          <Route path="home-page" element={<HomePage />} />
          <Route path="home-page/:id" element={<HomePage />} />
          <Route path="cms" element={<ContentManagement />} />


          <Route path="category" element={<Category />} />
          <Route path="category/:id" element={<Category />} />


        </Route>

      </Routes>
    </BrowserRouter>
  )
}

export default AppRoute