import { Link } from "react-router-dom";
import style from "./aboutusbanner.module.scss";
import Icon from "../../Icon";
import shape from "../../../Assets/Image/shape.png";
import bg_image from "../../../Assets/Image/bg-image.jpg";
const Aboutusbanner = () => {
  return (
    <section className={style.section}>
      <div className={`container`}>
        <div className={`${style.heading_wrapper}`}>
          <h1 className={`${style.title} secondary-font`}>
            We honor the profound cultural and spiritual importance of idols and
            products, delivering them across India with trust.
          </h1>
          <div className={`${style.btn_wrapper}`}>
            <Link className={`custom_btn`} to={`/`}>
              Contact us{" "}
              <span>
                <Icon
                  icon={"arrow"}
                  color={"#000"}
                  className={`ms-2`}
                  size={14}
                />
              </span>
            </Link>
          </div>
        </div>

        <div className={`${style.banner_wrapper}`}>
          <div className={`${style.shape}`}>
            <img src={shape} alt="shape about us" />
          </div>
          <div className={`${style.banner_container}`}>
            <img src={bg_image} alt="" srcset="" />
          </div>
        </div>

        <div className={`${style.description_wrapper}`}>
          <div className={`${style.descriptions}`}>
            <h3 className="secondary-font">Nationwide, Diverse, Sacred.</h3>
            <p className={`${style.text}`}>
              Wherever you reside, we showcase a collection of meticulously
              crafted idols, each a divine masterpiece reflecting our rich
              heritage.
            </p>
          </div>

          <a href="/" className={`${style.link}`}>
            <Icon icon={"arrow"} color={"#fff"} className={`ms-2`} size={14} />
            <span>Guruvayoor, Kerala</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Aboutusbanner;
