
import TitleHeading from "../TitleHeading"
import { useTitleHeading } from "../TitleHeading/useTitleHeading"
import ConnectCreate from "./connectCreate"
import { useConnectCreate } from "./useConnectCreate"

const Connect = () => {
    const {isListing, setIsListing} = useTitleHeading()
    const {data, id} =  useConnectCreate();
  return (
    <div className={`w-100`}>
            <div className={` `}>
                <div className={`row`}>
                    <TitleHeading title={`Media`} setIsListing={setIsListing} isListing={isListing} hideBtn/>
                    
                    <ConnectCreate data={data} id={id}/>
                   
                </div>
            </div>
        </div>
  )
}

export default Connect