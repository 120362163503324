import React from 'react'
import { Button, Table } from 'react-bootstrap';
import { useCategory } from './useCategory';

const CategoryList = ({handleView}) => {
    const { data, handleDelete, handleEdit } = useCategory({handleView});
    return (
        <div>
            <Table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Category Name</th>
                        <th>Price</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.map((item, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.title}</td>
                                    <td>{item.size}</td>
                                    <td>
                                        <Button
                                            type="button"
                                            variant="primary"
                                            className={`text-white`}
                                            onClick={() => handleEdit(item.id)}
                                        >Edit</Button>
                                        <Button
                                            type="button"
                                            variant="primary"
                                            className={`text-white ms-2`}
                                            onClick={() => handleDelete(item.id)}
                                        >Delete</Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default CategoryList