import ProductHeader from '../ProductHeader'
import ProductList from '../ProductList'
import style from './products.module.scss'
const Products = () => {
  return (
    <div className={`${style.root}`}>
        {/* <div className={`${style.product_heading}`}>
            <div className={`container`}>
                <ProductHeader/>
            </div>
        </div> */}





        <div className={style.product_list}>
            <div className={`container`}>
                <ProductList/>
            </div>
        </div>
    </div>
  )
}

export default Products