import { Formik } from 'formik'
import style from './auth.module.scss'
import { useAuth } from './useAuth'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Auth = () => {
    const {handleLogin, errorLogin} = useAuth()
    const navigate = useNavigate();

    useEffect(() => {
        const checkLogin  = localStorage.getItem("token") === null;
        if (!checkLogin) {navigate(`/admin/dashboard`)}
      }, [navigate]);
      
  return (
    <div className={`${style.auth_wrapper}`}>
        <div className={`${style.auth_form_wrapper}`}>

               <div className={`${style.heading_wrapper}`}>
                <h2>Admin Login</h2>
                <p>Welcome to Dashboard Authentication.</p>
               </div>
               <Formik
                initialValues={{
                    userName: '',
                    password: '',
                  }}
                  validate={values => {
                    const errors = {};
                    if (!values.userName) {
                        errors.userName = `Please enter username `
                    }
                    if (!values.password) {
                        errors.password = `Please enter password `
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {handleLogin(values)}}
               >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (

                <form action="" method='post' onSubmit={handleSubmit} className={`${style.form}`}>
                    <div className={`${style.form_wrapper}`}>
                        <input type="text" name="userName" placeholder='Username' value={values.userName} onChange={handleChange} onBlur={handleBlur} />
                        <span className={`errors`}>{errors.userName && errors.userName}</span>
                    </div>
                    <div className={`${style.form_wrapper}`}>
                        <input type="password" name="password" placeholder='Password' value={values.password} onChange={handleChange} onBlur={handleBlur} />
                        <span className={`errors`}>{errors.password && errors.password}</span>
                        <span className={`errors`}>{errorLogin && `Password or user name is incorrect please try again.`}</span>
                        
                    </div>
                    <div className={`${style.btn_wrapper}`}>
                        <button type="submit" className='btn btn-primary'>Login</button>
                    </div>
                </form>
                )}
            </Formik>
        </div>
    </div>
  )
}

export default Auth