import React from 'react'
import { Button, Table } from 'react-bootstrap';
import { useHomePage } from './useHomePage';

const HomePageList = ({setId, setIsListing}) => {
    const { data } = useHomePage();
    const handleClick = (id) => {
        setId(id)
        setIsListing(false)
    }
    return (
        <div>
            <Table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>typeName</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.map((item,index) => {
                            return (
                                <tr>
                                    <td>{index}</td>
                                    <td>{item.name}</td>
                                    <td>{item.type_name}</td>
                                    <td><Button type="button" variant="primary" className={`text-white`} onClick={() => handleClick(item._id)}>Edit</Button></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default HomePageList