import axios from "axios";
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../Config/api";

export const useBanner = ({ handleView }) => {
  const [isListing, setIsListing] = useState(true);
  const [data, setData] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const { id: idParams } = useParams()

  //get data from database
  useEffect(() => {
    axios.get(`${BASE_URL}/banner`).then((response) => {
      setData(response.data);

    });
    setTimeout(() => {
      setStatus(false)
    }, 1000);
  }, [status]);



  //fetch data from database for edit
  useEffect(() => {
    if (idParams) {
      axios.get(`${BASE_URL}/banner/${idParams}`).then((response) => {
        setDataEdit(response.data[0]);
      });
    }
  }, [idParams]);



  //handle delete
  const handleDelete = (id) => {
    axios.delete(`${BASE_URL}/banner/${id}`).then((response) => {
      if (response.data.success) {
        setStatus(true);
      }
    });
  }


  //handle edit
  const handleEdit = (id) => {
    handleView(true)
    navigate(`/admin/banner/${id}`)
  }


  return {
    isListing,
    setIsListing,
    data,
    handleDelete,
    status,
    handleEdit,
    dataEdit
  }
}
