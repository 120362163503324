import { useEffect } from "react"
import TitleHeading from "../TitleHeading"
import { useTitleHeading } from "../TitleHeading/useTitleHeading"
import HomePageCreate from "./homePageCreate"
import HomePageList from "./homePageList"
import { useHomePage } from "./useHomePage"

const HomePage = (props) => {
    const {isListing, setIsListing} = useTitleHeading()
    const {id, setId} = useHomePage()

    useEffect(() => {
        if (!id) {
            setId(null)
        }
    }, [id]);


  return (
    <div className={`w-100`}>
            <div className={` `}>
                <div className={`row`}>
                    <TitleHeading title={`Home sections`} setId={setId} id={id} setIsListing={setIsListing} isListing={isListing}/>
                    
                    {
                        isListing ?  
                        <HomePageList setIsListing={setIsListing} setId={setId} /> :
                        <HomePageCreate id={id}/> 
                        
                    }
                   
                </div>
            </div>
        </div>
  )
  
}

export default HomePage