import { SwiperSlide } from 'swiper/react'
import Slider from '../../Slider'
import style from './productSlider.module.scss'
import { useState } from 'react';
import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
} from "react-image-magnifiers";
import { BASE_URL } from '../../../Config/api';

const ProductSlider = ({ data }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const customSetting = {
        slidesPerView: 1,
        spaceBetween: 0,
        thumbs: { swiper: thumbsSwiper && !thumbsSwiper?.destroyed && thumbsSwiper }
    }
    const customSetting1 = {
        slidesPerView: 4,
        spaceBetween: 10,
        onSwiper: (e) => {
            setThumbsSwiper(e);
        }

    }




    return (
        <div className={style.slider_wrapper}>



            {/* install swiper slider 
            add slider 
            add good animation add magnification 
            add slider navs 
            add dots 
            add thumbnails 
        */}

            {
                data &&
                <div className={`${style.main_slider}`}>
                    <Slider propsSetting={customSetting}>
                        {
                            data?.map((item, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <GlassMagnifier
                                            imageSrc={`${BASE_URL}/uploads/${item?.filename}`}
                                            imageAlt="Example"
                                            largeImageSrc={`${BASE_URL}/uploads/${item?.filename}`} // Optional
                                        />
                                    </SwiperSlide>
                                )
                            })
                        }


                    </Slider>
                </div>
            }
            {
                data &&
                <div className={`${style.thumb_slider}`}>
                    <Slider propsSetting={customSetting1}>

                        {
                            data?.map((item, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className={style.image_wrapper}>
                                            <img loading="lazy" src={`${BASE_URL}/uploads/${item?.filename}`} alt="banner" />
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }

                    </Slider>
                </div>
            }

        </div>
    )
}

export default ProductSlider