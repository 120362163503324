import React from 'react'
import Aboutusbanner from './Aboutusbanner'
import HowYouReceive from './HowYouReceive'

const AboutUs = () => {
  return (
    <>
        <Aboutusbanner/>
        <HowYouReceive/>
    </>
  )
}

export default AboutUs