import { Formik } from 'formik';
import style from './bannerAdmin.module.scss'
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useBanner } from './useBanner';
import { BASE_URL } from '../../../Config/api';
import { useNavigate } from 'react-router-dom';
const BannerCreate = ({ handleView, isListing }) => {

    const { dataEdit } = useBanner({ handleView });
    const navigate = useNavigate()
    return (
        <div className={style.root}>
            <div className={` `}>
                <Formik enableReinitialize={true}
                    initialValues={{
                        banner_title: dataEdit?.title ? dataEdit.title : '',
                        banner_image: dataEdit?.image ? dataEdit.image : '',
                        banner_url: dataEdit?.link_text ? dataEdit.link_text : '',
                        banner_url_link: dataEdit?.link_url ? dataEdit.link_url : '',

                    }}
                    validate={values => {

                        const errors = {};
                        if (!values.banner_title) {
                            errors.banner_title = 'Required';
                        }
                        if (!values.banner_image) {
                            errors.banner_image = 'Required';
                        }
                        if (!values.banner_url) {
                            errors.banner_url = 'Required';
                        }
                        if (!values.banner_url_link) {
                            errors.banner_url_link = 'Required';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {


                        const formData = new FormData();
                        formData.append('title', values.banner_title);
                        formData.append('link_url', values.banner_url);
                        formData.append('link_text', values.banner_url_link);
                        formData.append('date', Date.now());
                        for (let index = 0; index < values.banner_image.length; index++) {
                            formData.append('image', values.banner_image[index]);
                        }
                        if (!dataEdit) {
                            axios.post(`${BASE_URL}/banner`, formData).then((response) => {
                                if (response?.data.status) {
                                    navigate(`/admin/banner`)
                                    handleView(!isListing)
                                }
                            });
                        }
                        else {
                            axios.put(`${BASE_URL}/banner/${dataEdit.id}`, formData).then((response) => {
                                if (response?.data.status) {
                                    navigate(`/admin/banner`)
                                    handleView(!isListing)
                                }
                            });
                        }
                    }}


                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Form onSubmit={handleSubmit} className={`row g-1 g-sm-2`}>
                            <Form.Group className="col-lg-6">
                                <Form.Label>Banner Title</Form.Label>
                                <Form.Control
                                    name="banner_title"
                                    type="text"
                                    placeholder="Enter title name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.banner_title}
                                />
                                <span className={`errors`}>{errors.banner_title && errors.banner_title}</span>
                            </Form.Group>


                            <Form.Group className="col-lg-6">
                                <Form.Label>Banner url</Form.Label>
                                <Form.Control
                                    name="banner_url"
                                    type="text"
                                    placeholder="Enter banner url title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.banner_url}
                                />
                                <span className={`errors`}>{errors.banner_url && errors.banner_url}</span>
                            </Form.Group>


                            <Form.Group className="col-lg-6">
                                <Form.Label>Banner url link</Form.Label>
                                <Form.Control
                                    name="banner_url_link"
                                    type="text"
                                    placeholder="Enter banner url link"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.banner_url_link}
                                />
                                <span className={`errors`}>{errors.banner_url_link && errors.banner_url_link}</span>
                            </Form.Group>





                            <Form.Group className="col-12">
                                <Form.Label>Banner Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    placeholder="banner file"
                                    onChange={(e) => setFieldValue("banner_image", e.target.files)}
                                />
                                <span className={`errors`}>{errors.banner_image && errors.banner_image}</span>
                            </Form.Group>
                            <small>Please upload an image that have 100x100 size, png jpg jpge is allowed</small>



                            <div className={`col-12 d-flex mt-4`}>
                                <Button variant="primary" type="submit" className='w-auto  px-3 py-2 text-white ms-auto'>
                                    Submit Details +
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>

        </div>
    )
}

export default BannerCreate