import React from 'react'
import style from './swiper.module.scss'
import {Swiper} from 'swiper/react';
import {Navigation, Thumbs} from "swiper";
import 'swiper/css';


function Slider({propsSetting, children}) {
    const setting = {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    }

    const customSetting = {
        ...setting,
        ...propsSetting,
    };
    return (
        <div className={
            style.root
        }>
            <Swiper {...customSetting}
                modules={
                    [Navigation, Thumbs]
            }>
                {children}
                <div className="swiper-button-next">{`>`}</div>
                <div className="swiper-button-prev">{`<`}</div>
            </Swiper>

        </div>
    )
}

export default Slider
