import axios from "axios";
import { useEffect, useState } from "react"
import { BASE_URL } from "../../Config/api";
import { useParams } from "react-router-dom";

export const useProductDetail = () => {
  const [data, setData] = useState(null);
  const { name  } = useParams()

    useEffect(() => {
        axios.get(`${BASE_URL}/detail/${name}`).then((response) => {
            setData(response.data)
        });
    }, [name]);

   
  return {
    data
  }
}
