import { useEffect, useState } from 'react'

export const useHeader = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', ()=> {
            window.scrollY > 200 ? setIsScrolled(true) :setIsScrolled(false)
        })
      }, []);
  return {
    isScrolled
  }
}
