import axios from "axios";
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../Config/api";


export const useCategory = ({ handleView }) => {
  const [isListing, setIsListing] = useState(true);
  const [data, setData] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const { id: idParams } = useParams()
  useEffect(() => {
    axios.get(`${BASE_URL}/category`).then((response) => {
      setData(response.data);
    });
    setTimeout(() => {
      setStatus(false)
    }, 1000);
  }, [status]);


  useEffect(() => {
    if (idParams) {
      axios.get(`${BASE_URL}/category/${idParams}`).then((response) => {
        setDataEdit(response.data[0]);
      });


    }

  }, [idParams]);


  const handleDelete = (id) => {
    axios.delete(`${BASE_URL}/category/${id}`).then((response) => {

      if (response.data.success) {
        setStatus(true);
      }
    });
  }


  const handleEdit = (id) => {
    navigate(`/admin/category/${id}`)
    handleView(false)
  }


  return {
    isListing,
    setIsListing,
    data,
    handleDelete,
    status,
    handleEdit,
    dataEdit
  }
}