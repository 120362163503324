import axios from "axios";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import ColorSelector from "./colorSelector";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../Config/api";

const ProductsCreate = ({ data, setIsListing, setEditData, category }) => {
  //get id
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <div className="w-100">
        <div className={`w-100`}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              product_title: data?.title || "",
              product_category: data?.category || "",
              product_image: data?.product_image || "",
              product_description: data?.description || "",
              product_size: data?.size || "",
              product_known_as: data?.known_as || "",
              product_stock_status: data?.stock_status || "",
              product_product_visibility: data?.product_visibility || "",
              product_color: data?.color || [],
              product_price: data?.price || "",
              product_featured: data?.product_featured || "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.product_title) {
                errors.product_title = "Required";
              }
              // if (!values.product_category) {
              //     errors.product_category = 'Required';
              // }
              if (!values.product_image && data?.product_image) {
                errors.product_image = "Required";
              }
              if (!values.product_description) {
                errors.product_description = "Required";
              }
              if (!values.product_size) {
                errors.product_size = "Required";
              }
              if (!values.product_known_as) {
                errors.product_known_as = "Required";
              }
              if (!values.product_stock_status) {
                errors.product_stock_status = "Required";
              }
              if (!values.product_product_visibility) {
                errors.product_product_visibility = "Required";
              }
              if (values.product_color.length === 0) {
                errors.product_color = "Required";
              }
              if (!values.product_price) {
                errors.product_price = "Required";
              }

              console.log(errors);
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              const formData = new FormData();
              formData.append("title", values.product_title);
              formData.append("category", values.product_category);
              formData.append("price", values.product_price);
              formData.append("description", values.product_description);
              formData.append("size", values.product_size);
              formData.append("known_as", values.product_known_as);
              formData.append("stock_status", values.product_stock_status);
              formData.append(
                "product_visibility",
                values.product_product_visibility
              );
              formData.append("product_featured", values.product_featured);
              formData.append("color", JSON.stringify(values.product_color));
              formData.append("date", Date.now());
              if (values.product_featured) {
                formData.append("featured_update", Date.now());
              }
              for (
                let index = 0;
                index < values.product_image.length;
                index++
              ) {
                formData.append("image", values.product_image[index]);
              }

              if (!id) {
                axios
                  .post(`${BASE_URL}/product`, formData)
                  .then((response) => { });
              } else {
                axios
                  .put(`${BASE_URL}/product/${id}`, formData)
                  .then((response) => {
                    if (response.data) {
                      setEditData(null);
                      navigate(`/admin/product/`);
                      setIsListing(true);
                    }
                  });
              }
              setSubmitting(false)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit} className={`row g-1 g-sm-2`}>
                <Form.Group className="col-lg-6">
                  <Form.Label>Product Title</Form.Label>
                  <Form.Control
                    name="product_title"
                    type="text"
                    placeholder="Enter product title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.product_title}
                  />
                  <span className={`errors`}>
                    {errors.product_title && errors.product_title}
                  </span>
                </Form.Group>

                <Form.Group className="col-lg-6">
                  <Form.Label>Product category</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="product_category"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.product_category}
                  >
                    <option>Select Category</option>
                    {category &&
                      category.map((item, index) => {
                        return <option value={item.id}>{item.title}</option>;
                      })}
                  </Form.Select>
                  <span className={`errors`}>
                    {errors.product_category && errors.product_category}
                  </span>

                  {/* <Form.Label>Product Title</Form.Label>
                                    <Form.Control
                                        name="product_title"
                                        type="text"
                                        placeholder="Enter product title"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.product_title}
                                    />
                                    <span className={`errors`}>{errors.product_title && errors.product_title}</span> */}
                </Form.Group>

                <Form.Group className="col-lg-6">
                  <Form.Label>Product size</Form.Label>
                  <Form.Control
                    name="product_size"
                    type="text"
                    placeholder="Enter product size"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.product_size}
                  />
                  <span className={`errors`}>
                    {errors.product_size && errors.product_size}
                  </span>
                </Form.Group>

                <Form.Group className="col-lg-6">
                  <Form.Label>Product stock status(count)</Form.Label>
                  <Form.Control
                    name="product_stock_status"
                    type="number"
                    placeholder="Enter product stock_status"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.product_stock_status}
                  />
                  <span className={`errors`}>
                    {errors.product_stock_status && errors.product_stock_status}
                  </span>
                </Form.Group>

                <Form.Group className="col-lg-12">
                  <Form.Label>Product price</Form.Label>
                  <Form.Control
                    name="product_price"
                    type="number"
                    placeholder="Enter product price"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.product_price}
                  />
                  <span className={`errors`}>
                    {errors.product_price && errors.product_product_visibility}
                  </span>
                </Form.Group>

                <Form.Group className="col-lg-6">
                  <Form.Label>Product known as</Form.Label>
                  <Form.Control
                    name="product_known_as"
                    type="text"
                    placeholder="Enter product known as"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.product_known_as}
                  />
                  <span className={`errors`}>
                    {errors.product_known_as && errors.product_known_as}
                  </span>
                </Form.Group>

                <Form.Group className="col-lg-6">
                  <Form.Label>Product Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Enter product file"
                    onChange={(e) =>
                      setFieldValue("product_image", e.target.files)
                    }
                    multiple
                  />
                  <span className={`errors`}>
                    {errors.product_image && errors.product_image}
                  </span>
                </Form.Group>

                <Form.Group className="col-lg-12">
                  <Form.Label>Product color</Form.Label>
                  {/* <Form.Control 
                                            name="product_color" 
                                            type="text" 
                                            placeholder="Enter product color"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.product_color}
                                        /> */}

                  <ColorSelector
                    value={values.product_color}
                    handleChange={setFieldValue}
                    handleBlur={handleBlur}
                  />
                  <span className={`errors`}>
                    {errors.product_color && errors.product_color}
                  </span>
                </Form.Group>

                <Form.Group className="col-lg-12">
                  <Form.Label>Product description</Form.Label>
                  <Form.Control
                    name="product_description"
                    type="text"
                    as={"textarea"}
                    placeholder="Enter product description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.product_description}
                  />
                  <span className={`errors`}>
                    {errors.product_description && errors.product_description}
                  </span>
                </Form.Group>

                <Form.Group className="col-lg-6">
                  <Form.Label>Product product visibility</Form.Label>
                  <Form.Select
                    name="product_product_visibility"
                    type="text"
                    placeholder="Enter product product visibility"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.product_product_visibility}
                  >
                    <option value={"show"}>{`Show`}</option>
                    <option value={"hidden"}>{`Hidden`}</option>
                  </Form.Select>
                  <span className={`errors`}>
                    {errors.product_product_visibility &&
                      errors.product_product_visibility}
                  </span>
                </Form.Group>

                <Form.Group className="col-lg-6">
                  <span className="check">
                    <div className={`check-inner`}>
                      <input
                        type="checkbox"
                        name="product_featured"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.product_featured}
                        id="product_featured"
                      />
                      <span className="icon-check"></span>
                      <label htmlFor="product_featured">
                        Set as featured product
                      </label>
                    </div>
                  </span>

                  <span className={`errors`}>
                    {errors.product_featured && errors.product_featured}
                  </span>
                </Form.Group>

                <div className={`col-12 d-flex mt-4`}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-auto  px-3 py-2 text-white ms-auto"
                  >
                    Submit Details +
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ProductsCreate;
