import { Outlet, useLocation, useNavigate } from "react-router-dom"
import Header from "../Header"
import '../app.scss'
import style from './adminLayout.module.scss'
import { useEffect } from "react"
const AdminLayout = () => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkLogin  = localStorage.getItem("token") === null;
    if (checkLogin) {navigate(`/admin`)}
  }, [navigate]);



  
  
  const checkIsLoginPage = location.pathname === "/admin";
  return (
    <div className={`w-100`}>
      {
        !checkIsLoginPage ? 
        <>
        <Header/>
        <main className={style.main}>
            <Outlet/>
        </main>
        </>
        :
          <Outlet/>
        }
    </div>
  )
}

export default AdminLayout