import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../Config/api";

export const useGalleryPage = () => {
    const [gallery, setGallery] = useState([]);
    const [newProduct, setNewProduct] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/gallery?limit=4`).then((response) => {
            setGallery(response.data);
        });
    }, []);


    useEffect(() => {
        axios.get(`${BASE_URL}/new-products`).then((response) => {
            setNewProduct(response.data);
        });

    }, []);
    return {
        gallery,
        newProduct
    }
}
