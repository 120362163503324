
import ProductsCreate from "./productCreate"
import ProductList from "./productList"
import TitleHeading from "../TitleHeading"
import { useTitleHeading } from "../TitleHeading/useTitleHeading"
import { useProducts } from "./useProducts"

const Products = () => {
    const { isListing, setIsListing } = useTitleHeading()
    const { data, setProductId, handleEditProduct, editData, setEditData, handleRemoveProduct, category } = useProducts({ useTitleHeading });
    const url_data = '/admin/product'

    return (
        <div className={`w-100`}>
            <div className={` `}>
                <div className={`row`}>
                    <TitleHeading setIsListing={setIsListing} isListing={isListing} url={url_data} />

                    {
                        isListing ?
                            <ProductList
                                data={data}
                                setProductId={setProductId}
                                changeView={setIsListing}
                                handleEditProduct={handleEditProduct}
                                handleRemoveProduct={handleRemoveProduct}
                            /> :
                            <ProductsCreate
                                data={editData}
                                setEditData={setEditData}
                                setIsListing={setIsListing}
                                // category={category}
                            />
                    }

                </div>
            </div>
        </div>
    )
}

export default Products