import GalleryCreate from '../Gallery/galleryCreate'
import GalleryList from '../Gallery/galleryList'
import TitleHeading from '../TitleHeading'
import { useTitleHeading } from '../TitleHeading/useTitleHeading'

const Gallery = () => {
    const {isListing, setIsListing} = useTitleHeading()
  return (
    <div className={`w-100`}>
            <div className={` `}>
                <div className={`row`}>
                    <TitleHeading title={`Gallery`} setIsListing={setIsListing} isListing={isListing}/>
                    
                    {
                        isListing ?  
                        <GalleryList /> :
                        <GalleryCreate setIsListing={setIsListing}/>
                    }
                   
                </div>
            </div>
        </div>
  )
}

export default Gallery