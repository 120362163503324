import { Formik } from 'formik';
import style from './category.module.scss'
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useCategory } from './useCategory';
import { BASE_URL } from '../../../Config/api';
const CategoryCreate = ({ handleView }) => {

    const { dataEdit } = useCategory({ handleView });

    return (
        <div className={style.root}>
            <div className={` `}>
                <Formik enableReinitialize={true}
                    initialValues={{
                        category_title: dataEdit?.title ? dataEdit.title : '',
                        category_image: dataEdit?.image ? dataEdit.image : '',

                    }}
                    validate={values => {

                        const errors = {};
                        if (!values.category_title) {
                            errors.category_title = 'Required';
                        }
                        if (!values.category_image) {
                            errors.category_image = 'Required';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {


                        const formData = new FormData();
                        formData.append('title', values.category_title);
                        formData.append('date', Date.now());
                        for (let index = 0; index < values.category_image.length; index++) {
                            formData.append('image', values.category_image[index]);
                        }
                        if (!dataEdit) {
                            axios.post(`${BASE_URL}/category`, formData).then((response) => {

                            });
                        }
                        else {
                            axios.put(`${BASE_URL}/category/${dataEdit.id}`, formData).then((response) => {

                            });
                        }





                    }}


                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Form onSubmit={handleSubmit} className={`row g-1 g-sm-2`}>
                            <Form.Group className="col-lg-6">
                                <Form.Label>Category Title</Form.Label>
                                <Form.Control
                                    name="category_title"
                                    type="text"
                                    placeholder="Enter title name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.category_title}
                                />
                                <span className={`errors`}>{errors.category_title && errors.category_title}</span>
                            </Form.Group>







                            <Form.Group className="col-6">
                                <Form.Label>Category Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    placeholder="category file"
                                    onChange={(e) => setFieldValue("category_image", e.target.files)}
                                />
                                <span className={`errors d-block`}>{errors.category_image && errors.category_image}</span>
                                <small className={`d-block mt-2`}>Please upload an image that have 100x100 size, png jpg jpge is allowed</small>
                            </Form.Group>




                            <div className={`col-12 d-flex mt-4`}>
                                <Button variant="primary" type="submit" className='w-auto  px-3 py-2 text-white ms-auto'>
                                    Submit Details +
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>

        </div>
    )
}

export default CategoryCreate