import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import style from './header.module.scss'
import logo from '../../Assets/Image/new-logo.svg'
import Icon from '../Icon'
import { useHeader } from './useHeader'
function Header({ handleMenu }) {
  const { isScrolled } = useHeader();
  return (
    <header className={`${style.root} ${isScrolled ? style.active : ''}`}>
      <div className="container">
        <div className={style.logo}>
          <Link to={'/'} >
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className={style.menu}>
          <Nav
            activeKey="/"

          >
            <Nav.Item>
              <Link className={`nav-link`} to="/">Home</Link>
            </Nav.Item>
            <Nav.Item>
              <Link className={`nav-link`} to="/about-us">About Us</Link>
            </Nav.Item>
            <Nav.Item>
              <Link className={`nav-link`} to='/products'>Collections</Link>
            </Nav.Item>
            <Nav.Item>
              <Link className={`nav-link`} to='/gallery'>Gallery</Link>
            </Nav.Item>
            <div className={style.contact}>
              <Nav.Item>
                <Link><div className={style.instagram_icon}></div></Link>
              </Nav.Item>
              <Nav.Item>
                <Link className={`nav-link`} eventkey="link-1"><div className={`${style.fb_icon} me-2`}><Icon icon={'instagram'} color={'#000'} size={18} /></div>/guruvayoor_online_shopping</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to={`/contact-us`} className={`nav-link`} eventkey="link-1">Contact Us</Link>
              </Nav.Item>
            </div>

          </Nav>
        </div>
        <div className={`${style.mobile_menu_section}`}>
          <div className={`${style.instagram_icon}`}><Icon icon={'instagram'} color={'#000'} size={18} /></div>
          <button className={`${style.mobile_menu}`} onClick={handleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header