import React from 'react'
import {Outlet} from 'react-router-dom'
import Footer from '../Footer'
import Header from '../Header'
import MobileMenu from '../MobileMenu'
import { useLayout } from './useLayout'

function Layout() {
    const  {handleMenu, isMenuOpen} = useLayout()
    return (
        <>
            <Header handleMenu={handleMenu}/>
            <MobileMenu isMenuOpen={isMenuOpen} handleMenu={handleMenu}/>
            <main>
                <Outlet/>
            </main>
            <Footer/>
        </>
    )
}

export default Layout
