import ProductSimple from '../ProductSimple'
import style from './productList.module.scss'
import { useProductList } from './useProductList'
const ProductList = () => {
    const { data } = useProductList();
    return (
        <div className={style.root}>
            <div className={`row g-1 g-sm-2 gy-lg-2`}>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className={`col-6 col-md-4 col-lg-3`}>
                                <ProductSimple {...item} />
                            </div>
                        )
                    })
                }


            </div>
        </div>
    )
}

export default ProductList