import { Button, Table } from "react-bootstrap";
import { useGallery } from "./useGallery";

const GalleryList = ({ handleView }) => {
  const { data, handleDelete, handleEdit } = useGallery({ handleView });
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Product Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            data && data.map((item, id) => {
              return (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{item.title}</td>
                  <td>
                    <Button type="button" variant="primary" className={`text-white`} onClick={() => handleEdit()}>Edit</Button>
                    <Button type="button" variant="primary" className={`text-white ms-2`} onClick={() => handleDelete(item._id)}>Delete</Button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </div>
  )
}

export default GalleryList