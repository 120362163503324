import axios from "axios";
import { useEffect, useState } from "react"
import { BASE_URL } from "../../../Config/api";

export const useConnectCreate = () => {
  const [data, setData] = useState(null);
  const [id, setId] = useState(null);
  useEffect(() => {
    axios.get(`${BASE_URL}/media`).then((response) => {
      setData(response.data[0]);
      setId(response.data[0].id)
    });
  }, []);

  return {
    data,
    id
  }
}
