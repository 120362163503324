import React from 'react'
import style from './testimonials.module.scss'


function Testimonials({ data }) {
  return (
    <div className={style.root}>
      <div className={`container`}>
        <div className={`${style.title}`}>
          <h4 className={`secondary-font ttl-1`}>Happy Clients</h4>
        </div>
        <div className={`row g-1 g-sm-2`}>
          {data && data.map((item, index) => {
            return (
              <div className={`col-lg-4`} key={index}>
                <div className={style.comment_wrapper}>
                  <p className={style.comment}>{item.message}</p>
                  <h4 className={`secondary-font ttl-3`}>{item.name}</h4>
                  <h6>{item.designation}</h6>
                </div>
              </div>
            )
          })}


        </div>
      </div>
    </div>
  )
}

export default Testimonials