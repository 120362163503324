import { Formik } from 'formik';
import style from './whatwedo.module.scss'
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useWhatWeDo } from './useWhatWeDo';
import ModalSelector from '../ModalSelector';
import { BASE_URL } from '../../../Config/api';
const WhatWeDoCreate = () => {
    const { data, id, product, isModelOpen, setIsModelOpen, handleProduct, productList } = useWhatWeDo()

    return (
        <div className={style.root}>

            <div className={` `}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        what_we_do_title: data?.title || '',
                        what_we_do_main_title: data?.main_title || '',
                        what_we_do_description: data?.description || '',
                        what_we_do_link_text: data?.url_text || '',
                        what_we_do_link_url: data?.url_link || '',
                        what_we_do_products: data?.products || ''

                    }}
                    validate={values => {

                        const errors = {};
                        if (!values.what_we_do_title) {
                            errors.what_we_do_title = 'Required';
                        }
                        if (!values.what_we_do_main_title) {
                            errors.what_we_do_main_title = 'Required';
                        }
                        if (!values.what_we_do_description) {
                            errors.what_we_do_description = 'Required';
                        }
                        if (!values.what_we_do_link_text) {
                            errors.what_we_do_link_text = 'Required';
                        }
                        if (!values.what_we_do_link_url) {
                            errors.what_we_do_link_url = 'Required';
                        }
                        if (values.what_we_do_products.length === 0) {
                            errors.what_we_do_products = 'Required';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {


                        const formData = new FormData();
                        formData.append('title', values.what_we_do_title);
                        formData.append('main_title', values.what_we_do_main_title);
                        formData.append('description', values.what_we_do_description);
                        formData.append('url_text', values.what_we_do_link_text);
                        formData.append('url_link', values.what_we_do_link_url);
                        formData.append('products', JSON.stringify(values.what_we_do_products));
                        formData.append('date', Date.now());

                        axios.put(`${BASE_URL}/what-we-do/${id}`, formData).then((response) => {
                            console.log(response.data);
                        });

                    }}


                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Form onSubmit={handleSubmit} className={`row g-1 g-sm-2`}>


                            {/* name */}
                            <Form.Group className="col-lg-12">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    name="what_we_do_title"
                                    type="text"
                                    placeholder="Enter title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.what_we_do_title}
                                />
                                <span className={`errors`}>{errors.what_we_do_title && errors.what_we_do_title}</span>
                            </Form.Group>

                            {/* designation */}
                            <Form.Group className="col-lg-12">
                                <Form.Label>Main title</Form.Label>
                                <Form.Control
                                    name="what_we_do_main_title"
                                    type="text"
                                    as={"textarea"}
                                    placeholder="Enter main title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.what_we_do_main_title}
                                />
                                <span className={`errors`}>{errors.what_we_do_main_title && errors.what_we_do_main_title}</span>
                            </Form.Group>




                            {/* message */}
                            <Form.Group className="col-12">
                                <Form.Label>description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="what_we_do_description"
                                    rows={3}
                                    placeholder="Enter description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.what_we_do_description}
                                />
                                <span className={`errors`}>{errors.what_we_do_description && errors.what_we_do_description}</span>
                            </Form.Group>


                            {/* link text */}
                            <Form.Group className="col-lg-6">
                                <Form.Label>Link title</Form.Label>
                                <Form.Control
                                    name="what_we_do_link_text"
                                    type="text"
                                    placeholder="Enter url title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.what_we_do_link_text}
                                />
                                <span className={`errors`}>{errors.what_we_do_link_text && errors.what_we_do_link_text}</span>
                            </Form.Group>



                            {/* link url */}
                            <Form.Group className="col-lg-6">
                                <Form.Label>Link url</Form.Label>
                                <Form.Control
                                    name="what_we_do_link_url"
                                    type="text"
                                    placeholder="Enter url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.what_we_do_link_url}
                                />
                                <span className={`errors`}>{errors.what_we_do_link_url && errors.what_we_do_link_url}</span>
                            </Form.Group>

                            <div className={`col-12 d-flex mt-4 flex-column w-25`}>
                                <Button variant="secondary" type="button" className='w-auto px-4 text-white' onClick={() => setIsModelOpen(true)}>
                                    Select Product
                                </Button>
                                <span className={`errors`}>{errors.what_we_do_products && errors.what_we_do_products}</span>
                            </div>



                            {product && <ModalSelector setFieldValue={setFieldValue} show={isModelOpen} handleModal={setIsModelOpen} data={product} handleProduct={handleProduct} productList={productList} />}












                            <div className={`col-12 d-flex mt-4`}>
                                <Button variant="primary" type="submit" className='w-auto px-4 text-white ms-auto '>
                                    Update Details
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>






        </div>
    )
}

export default WhatWeDoCreate