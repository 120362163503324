import { useState } from "react"


export const useHeader = () => {
    const [open, setOpen] = useState(false)
  return {
    setOpen,
    open
  }
}
