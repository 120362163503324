import { Formik } from 'formik';
import style from './connact.module.scss'
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { BASE_URL } from '../../../Config/api';
const ConnectCreate = ({ data, id }) => {
    return (
        <div className={style.root}>
            <div className={` `}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        connect_description: data?.description || '',
                        connect_url_text: data?.url_link || '',
                        connect_url_link: data?.url_text || '',
                        connect_title: data?.title || '',
                        connect_facebook_url: data?.facebook_url || '',
                        connect_instagram_url: data?.instagram_url || '',
                        connect_product_url: data?.product_url || '',
                        connect_call_url: data?.call_url || '',

                    }}
                    validate={values => {

                        const errors = {};
                        if (!values.connect_description) {
                            errors.connect_description = 'Required';
                        }
                        if (!values.connect_url_text) {
                            errors.connect_url_text = 'Required';
                        }
                        if (!values.connect_url_link) {
                            errors.connect_url_link = 'Required';
                        }
                        if (!values.connect_title) {
                            errors.connect_title = 'Required';
                        }
                        if (!values.connect_facebook_url) {
                            errors.connect_facebook_url = 'Required';
                        }
                        if (!values.connect_instagram_url) {
                            errors.connect_instagram_url = 'Required';
                        }
                        if (!values.connect_product_url) {
                            errors.connect_product_url = 'Required';
                        }
                        if (!values.connect_call_url) {
                            errors.connect_call_url = 'Required';
                        }


                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {


                        const formData = new FormData();

                        formData.append('description', values.connect_description);
                        formData.append('url_text', values.connect_url_text);
                        formData.append('url_link', values.connect_url_link);
                        formData.append('title', values.connect_title);
                        formData.append('facebook_url', values.connect_facebook_url);
                        formData.append('instagram_url', values.connect_instagram_url);
                        formData.append('product_url', values.connect_product_url);
                        formData.append('call_url', values.connect_call_url);
                        formData.append('date', Date.now());




                        // #FIXME this should be replaced in future


                        axios.put(`${BASE_URL}/media/${id}`, formData).then((response) => {

                        });



                    }}


                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Form onSubmit={handleSubmit} className={`row g-1 g-sm-2`}>

                            {/* description */}
                            <Form.Group className="col-lg-12">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    name="connect_description"
                                    type="text"
                                    as={'textarea'}
                                    placeholder="Enter description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.connect_description}
                                />
                                <span className={`errors`}>{errors.connect_description && errors.connect_description}</span>
                            </Form.Group>


                            {/* link tile */}
                            <Form.Group className="col-lg-6">
                                <Form.Label>Link title</Form.Label>
                                <Form.Control
                                    name="connect_url_text"
                                    type="text"
                                    placeholder="Enter button link title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.connect_url_text}
                                />
                                <span className={`errors`}>{errors.connect_url_text && errors.connect_url_text}</span>
                            </Form.Group>


                            {/* link url */}
                            <Form.Group className="col-lg-6">
                                <Form.Label>Link url</Form.Label>
                                <Form.Control
                                    name="connect_url_link"
                                    type="text"
                                    placeholder="Enter button link url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.connect_url_link}
                                />
                                <span className={`errors`}>{errors.connect_url_link && errors.connect_url_link}</span>
                            </Form.Group>

                            <hr className={`mt-4 mb-2`} />

                            {/* title */}
                            <Form.Group className="col-lg-12">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    name="connect_title"
                                    type="text"
                                    placeholder="Enter title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.connect_title}
                                />
                                <span className={`errors`}>{errors.connect_title && errors.connect_title}</span>
                            </Form.Group>






                            {/* facebook url */}
                            <Form.Group className="col-lg-6">
                                <Form.Label>Facebook url</Form.Label>
                                <Form.Control
                                    name="connect_facebook_url"
                                    type="text"
                                    placeholder="Add facebook url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.connect_facebook_url}
                                />
                                <span className={`errors`}>{errors.connect_facebook_url && errors.connect_facebook_url}</span>
                            </Form.Group>







                            {/* instagram url */}
                            <Form.Group className="col-lg-6">
                                <Form.Label>instagram url</Form.Label>
                                <Form.Control
                                    name="connect_instagram_url"
                                    type="text"
                                    placeholder="Add instagram url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.connect_instagram_url}
                                />
                                <span className={`errors`}>{errors.connect_instagram_url && errors.connect_instagram_url}</span>
                            </Form.Group>






                            {/* product url */}
                            <Form.Group className="col-lg-6">
                                <Form.Label>Product url</Form.Label>
                                <Form.Control
                                    name="connect_product_url"
                                    type="text"
                                    placeholder="Add product url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.connect_product_url}
                                />
                                <span className={`errors`}>{errors.connect_product_url && errors.connect_product_url}</span>
                            </Form.Group>




                            {/* call url */}
                            <Form.Group className="col-lg-6">
                                <Form.Label>Call url</Form.Label>
                                <Form.Control
                                    name="connect_call_url"
                                    type="text"
                                    placeholder="Add call url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.connect_call_url}
                                />
                                <span className={`errors`}>{errors.connect_call_url && errors.connect_call_url}</span>
                            </Form.Group>




                            <div className={`col-12 d-flex mt-4`}>
                                <Button variant="primary" type="submit" className='w-auto px-4 text-white'>
                                    Update Details
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>

        </div>
    )
}

export default ConnectCreate