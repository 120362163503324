import { Formik } from 'formik';
import style from './homePage.module.scss'
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../../../Config/api';
const HomePageCreate = ({ id }) => {
    // #TODO banner created 
    // banner link  ok 
    // banner url  ok
    // every thing is capitalized even place holders ok 
    return (
        <div className={style.root}>
            <div className={` `}>
                <Formik
                    initialValues={{
                        home_name: id ? 'data' : '',
                        home_type_name: id ? 'data' : '',

                    }}
                    validate={values => {

                        const errors = {};
                        if (!values.home_name) {
                            errors.home_name = 'Required';
                        }
                        if (!values.home_type_name) {
                            errors.home_type_name = 'Required';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {


                        const formData = new FormData();
                        formData.append('name', values.home_name);
                        formData.append('type_name', values.home_type_name);
                        formData.append('date', Date.now());

                        if (id) {
                            axios.put(`${BASE_URL}/home-page/${id}`, formData).then((response) => {

                            });
                        }
                        else {
                            axios.post(`${BASE_URL}/home-page`, formData).then((response) => {

                            });
                        }






                    }}


                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Form onSubmit={handleSubmit} className={`row g-1 g-sm-2`}>
                            <Form.Group className="col-lg-6">
                                <Form.Label>Home Page section name</Form.Label>
                                <Form.Control
                                    name="home_name"
                                    type="text"
                                    placeholder="Enter title name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.home_name}
                                />
                                <span className={`errors`}>{errors.home_name && errors.home_name}</span>
                            </Form.Group>


                            <Form.Group className="col-lg-6">
                                <Form.Label>Banner url</Form.Label>
                                <Form.Control
                                    name="home_type_name"
                                    type="text"
                                    placeholder="Enter banner url title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.home_type_name}
                                />
                                <span className={`errors`}>{errors.home_type_name && errors.home_type_name}</span>
                            </Form.Group>





                            <div className={`col-12 d-flex mt-4`}>
                                <Button variant="primary" type="submit" className='w-auto  px-3 py-2 text-white ms-auto'>
                                    Submit Details +
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>

        </div>
    )
}

export default HomePageCreate