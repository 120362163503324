import axios from "axios";
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../Config/api";

export const useProducts = () => {
  const [isListing, setIsListing] = useState(true);
  const [data, setData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [category, setCategory] = useState(null);
  const [isColorPaletteOpen, setIsColorPaletteOpen] = useState(false);
  const [color, setColor] = useState(null);
  const [colorText, setColorText] = useState(null);
  const [colorArray, setColorArray] = useState([]);
  const [colorArrayIndex, setColorArrayIndex] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams()



  useEffect(() => {


    if (id) {
      axios.get(`${BASE_URL}/product/${id}`).then((response) => {
        setEditData(response.data, 'edit');
        setData(null)
      });
    } else {
      axios.get(`${BASE_URL}/product`).then((response) => {
        setData(response.data);
        setEditData(null)
      });
    }


  }, [id]);


  // useEffect(() => {
  //   axios.get(`${BASE_URL}/category`).then((response) => {
  //     setCategory(response.data);
  //       // console.log(response.data);
  //     });
  // }, []);




  const handleColorArray = () => {
    const newColor = [...colorArray, { color, colorText }]
    setColorArray(newColor);
  }

  const updateColorArray = (index, currentColor) => {
    const newColor = [...colorArray]
    newColor[index].color = currentColor;
    setColorArray(newColor);
  }

  const handleRemoveColor = (index) => {
    const newColor = [...colorArray];

    newColor.splice(index, 1);
    setColorArray(newColor);
  }

  const handleEditProduct = (fn, id) => {
    navigate(`/admin/product/${id}`)
    fn(false)
  }


  const handleTextChange = (e, index) => {
    const newColor = [...colorArray]
    newColor[index].colorText = e.target.value;
  }



  const handleRemoveProduct = (id) => {
    axios.delete(`${BASE_URL}/product/${id}`).then((response) => {
      axios.get(`${BASE_URL}/product`).then((response) => {
        setData(response.data);
      });
    });
  }

  return {
    isListing,
    setIsListing,
    data,
    setIsColorPaletteOpen,
    isColorPaletteOpen,
    setColor,
    color,
    colorArray,
    handleColorArray,
    setColorArray,
    updateColorArray,
    colorArrayIndex,
    setColorArrayIndex,
    handleRemoveColor,
    handleEditProduct,
    editData,
    setEditData,
    handleRemoveProduct,
    category,
    setColorText,
    colorText,
    handleTextChange
  }
}
