import { Link } from 'react-router-dom'
import style from './emptyPage.module.scss'
import Icon from '../Icon'
const EmptyPage = () => {
  return (
    <div className={`${style.empty}`}>


      <h1>404</h1>
      <p>The page you are looking for not exist!!</p>
      <Link className={`custom_btn`} to={`/`} >
        Home page <span><Icon icon={'arrow'} color={'#000'} className={`ms-2`} size={14} /></span>
      </Link>
    </div>
  )
}

export default EmptyPage