import Icon from "../../Icon";
import style from "./howYouReceive.module.scss";
const HowYouReceive = () => {
  return (
    <section className={`${style.section}`}>
      <div className={`container`}>

        <div className={`${style.heading_wrapper}`}>
          <h3 className={`${style.title} secondary-font`}>
            How you receive our products{" "}
          </h3>
        </div>
        <div className={`${style.list_wrapper}`}>
          <ul>
            <li>
              <h5 className={`secondary-font`}>Pick your order</h5>
              <p>Discover your ideal divine idol from our Curated Selection.</p>
            </li>
            <li>
              <h5 className={`secondary-font`}>Enquire with us</h5>
              <p>
                Feel free to contact us for information and secure your order
                today.
              </p>
            </li>
            <li>
              <h5 className={`secondary-font`}>
                Safely packing orders!
              </h5>
              <p>
                Our team will carefully and securely pack your order to ensure
                it's safe arrival.
              </p>
            </li>
            <li>
              <h5 className={`secondary-font`}>Get your order</h5>
              <p>
                Receive your order with utmost safety, ensuring it reaches you
                securely.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className={`${style.shop_now_wrapper}`}>
        <h4 className={`secondary-font`}>Order your product now</h4>
        <a href="/" className={`${style.link}`}>
          <Icon icon={"arrow"} color={"#fff"} className={`ms-2`} size={14} />
          <span>Shop Now</span>
        </a>
      </div>
    </section>
  );
};

export default HowYouReceive;
