import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../Config/api";


export const useAuth = () => {
    const [errorLogin, setError] = useState(false);
    const navigate = useNavigate();



    const handleLogin = (values) => {
        const formData = new FormData();
        formData.append('userName', values.userName);
        formData.append('password', values.password);

        axios.post(`${BASE_URL}/auth`, formData).then((response) => {
            if (response.data.status === 'success') {
                navigate(`/admin/dashboard`)
                localStorage.setItem("token", response.data.token.toString());
            }
            else {
                navigate(`/admin`)
                setError(true);
            }
        });
    }

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate(`/admin`)
    }
    return {
        handleLogin,
        errorLogin,
        handleLogout
    }
}
