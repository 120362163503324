import { useEffect } from 'react'
import ProductSimple from '../../ProductSimple'
import style from './modalSelector.module.scss'
import { Button, Modal } from 'react-bootstrap'


const ModalSelector = ({ setFieldValue, data, show, handleModal, handleProduct, productList }) => {
    useEffect(() => {
        setFieldValue("what_we_do_products", productList.filter(item => item.count !== null))
    }, [setFieldValue, productList]);
    return (
        // onHide={handleClose}
        <Modal show={show} className={style.modal} centered >

            <Modal.Body>

                <h3 className='mb-3'>Please select from listed products.</h3>
                <button type='button' className={style.close} onClick={() => handleModal(false) }></button>

                <div className={`row g-1 g-sm-2`}>
                    {
                        data && data.map((item, index) => {
                            


                            return (
                                <div className={`col-lg-3`} key={index}>

                                    <div className={`${style.product_wrapper}`}>
                                        <span className={style.checkbox}>
                                            <input type="checkbox" onChange={ () => handleProduct(item.id)} />
                                            {productList[index]?.count  && <span>{productList[index]?.count}</span>}
                                        </span>
                                        <ProductSimple  {...item} />
                                    </div>

                                </div>
                            )
                        })
                    }

                </div>



            </Modal.Body>

        </Modal>
    )
}

export default ModalSelector