import React from 'react'
import { Link } from 'react-router-dom'
import style from './footer.module.scss'
function Footer() {
  return (
    <div className={style.root}>
      <div className={`container`}>
        <div className={style.inner_wrapper}>
          <p>All rights reserved © 2023 guruvayoor_online_shopping</p>
          <p>
            <Link to={'/'}>About Us</Link>|
            <Link to={'/'}>Collections</Link>|
            <Link to={'/'}>Gallery</Link>|
            <Link to={'/'}>Testimonials</Link>|
            <Link to={'/'}>Privacy and policy</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer