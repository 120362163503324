import { Button } from 'react-bootstrap'
const TitleHeading = ({ isListing, setIsListing, title, setId, url, hideBtn }) => {
    return (
        <div className={`w-100 my-4 d-flex align-items-center`}>
            <h3 className={`fs-5`}>{`${title || `Product`} Update`}</h3>

            {(isListing) ?
                <>
                    {
                        !hideBtn &&
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={setIsListing}
                            className={`ms-auto px-3 py-2`}>
                            {`Create new ${title || `Product`}`} <span className={`ps-2`}>+</span>
                        </Button>
                    }

                </>

                :
                <>
                    {
                        !hideBtn &&
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={setIsListing}
                            className={`ms-auto px-3 py-2`}>
                            {`View ${title || `Product`}`}
                        </Button>
                    }
                </>

            }
        </div>
    )
}

export default TitleHeading