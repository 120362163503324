import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../Icon'
import ProductSimple from '../ProductSimple'
import style from './whatWedo.module.scss'
function WhatWedo({ whatWeDoData, whatWeDoProducts }) {
  const data = whatWeDoData?.[0]?.data?.[0];
  return (
    <div className={style.root}>
      {
        data &&
        <div className={`container`}>
          {/* <div className={`${style.title}`}>
            <h4 className={`secondary-font ttl-1`}>What we do ?</h4>
            <Link className={`custom_btn d-none d-lg-flex`} to={`/products`} >
              <span>Explore Latest Products</span> <span><Icon icon={'arrow'} color={'#000'} size={14} /></span>
            </Link>
          </div> */}
          <div className={`row g-1 g-sm-2`}>
            <div className={`col-lg-6`}>
              <div className={style.text_wrapper}>
                <h4 className={`secondary-font ttl-3`}>{data.main_title}</h4>
                <p>{data.description}</p>
                <Link className={`custom_btn`} to={data.url_link} >
                  <span>{data.url_text}</span> <span><Icon icon={'arrow'} color={'#000'} size={14} /></span>
                </Link>
              </div>
            </div>
            {
              whatWeDoProducts && whatWeDoProducts.map((item, index) => {
                return (
                  <div className={`col-6 col-lg-3`} key={index}>
                    <ProductSimple {...item} />
                  </div>
                )
              })
            }
            {/* <div className={`col-lg-3`}>
            <ProductSimple />
          </div>
          <div className={`col-lg-3`}>
            <ProductSimple />
          </div> */}
          </div>
        </div>
      }
    </div>
  )
}

export default WhatWedo