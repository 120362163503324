import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import ProductSimple from '../ProductSimple';
import style from './newProducts.module.scss'

function NewProducts({ productData, hasWaves }) {

  return (
    <div className={`${style.root} ${hasWaves ? style.waves : ''}`}>
      <div className={`container`}>
        <div className={`${style.title}`}>
          <h4 className={`secondary-font ttl-1`}>New Products</h4>
          <Link className={`custom_btn d-none d-lg-flex`} to={`/products`} >
            Explore Latest Products <span><Icon icon={'arrow'} color={'#000'} className={`ms-2`} size={14} /></span>
          </Link>
        </div>
        <div className={`row g-1 g-sm-2`}>
          {
            productData && productData.slice(0, 4).map((item, index) => {
              return (
                <div className={`col-6 col-lg-3`} key={index}>
                  <ProductSimple {...item} />
                </div>
              )
            })
          }
          
        </div>
      </div>
    </div>
  )
}

export default NewProducts