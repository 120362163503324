import { Link } from 'react-router-dom'
import style from './mobileMenu.module.scss'
const MobileMenu = ({ isMenuOpen, handleMenu }) => {
    const activeCLass = isMenuOpen ? style.active : '';
    return (
        <>
            <div className={
                `${style.mobile} ${activeCLass}`
            }>
                <ul>
                    <li>
                        <Link to={`/`} onClick={() => handleMenu(false)}>Home</Link>
                    </li>
                    <li>
                        <Link to={`/about-us`} onClick={() => handleMenu(false)}>About Us</Link>
                    </li>
                    <li>

                        <Link to={`/products`} onClick={() => handleMenu(false)}>Collections</Link>
                    </li>
                    <li>

                        <Link to={`/gallery`} onClick={() => handleMenu(false)}>Gallery</Link>
                    </li>
                    <li>

                        <Link to={`/contact-us`} onClick={() => handleMenu(false)}>Contact Us</Link>
                    </li>
                </ul>
            </div>
            <div className={
                `${style.overlay}  ${activeCLass}`
            } onClick={handleMenu}></div>
        </>
    )
}

export default MobileMenu
