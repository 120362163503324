import { Button, Table } from "react-bootstrap"

const ProductList = ({ data, handleEditProduct, changeView, handleRemoveProduct }) => {
    return (
        <div>
            <Table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody> {
                    data && data.map((item) => {
                        return (
                            <tr>
                                <td>{
                                    item.unique_id
                                }</td>
                                <td>{
                                    item.title
                                }</td>
                                <td>{
                                    item.size
                                }</td>
                                <td>
                                    <Button type="button" variant="primary"
                                        className={`text-white`}
                                        onClick={
                                            () => handleEditProduct(changeView, item.id)
                                        }>Edit</Button>
                                    <Button type="button" variant="primary"
                                        className={`text-white ms-2`}
                                        onClick={
                                            () => handleRemoveProduct(item._id)
                                        }>remove</Button>
                                </td>
                            </tr>
                        )
                    })
                } </tbody>
            </Table>
        </div>
    )
}

export default ProductList
