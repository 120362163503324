import React from "react";
import { useGalleryPage } from "./useGalleryPage";
import style from "./galleryPage.module.scss";
import { BASE_URL } from "../../Config/api";
import NewProducts from "../NewProducts";

const GalleryPage = () => {
  const { gallery, newProduct } = useGalleryPage();
  return (
    <>
      <section className={style.wrapper}>
        <div className={`container`}>
          <div className={`${style.inner_wrapper}`}>
            <div className={`${style.heading}`}>
              <h1 className={`${style.title} ttl-2 secondary-font`}>
                Explore the artistry and spirituality of Divine Idols India in our captivating gallery.
              </h1>
            </div>
            <div className={`${style.images}`}>
              <div className={`${style.image_inner} g-1 row`}>
                {gallery &&
                  gallery.map((item, index) => {
                    return (
                      <div
                        className={`${style.image_item} col-6 col-lg-3`}
                        key={index}
                      >
                        <div className={`${style.image}`}>
                          <img
                            loading="lazy"
                            src={`${BASE_URL}/uploads/${item.image.filename}`}
                            alt="banner"
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewProducts productData={newProduct} hasWaves />
    </>
  );
};

export default GalleryPage;
