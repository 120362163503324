import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../Icon'
import style from './contactSection.module.scss'

import image from '../../Assets/Image/bg-image.jpg'
function ContactSection({ mediaData }) {
  const data = mediaData?.[0].data?.[0];
  return (
    <div className={style.root}>
      {data &&
        <div className={`container`}>
          <div className={style.inner_wrapper}>
            {/* image */}
            <div className={style.image_wrapper}>
              <img loading="lazy" src={image} alt="banner" />
            </div>

            {/* text wrapper */}
            <div className={`${style.text_wrapper} row`}>

              {/* description */}
              <div className={`col-12 col-lg-7`}>
                <h3 className={`secondary-font ttl-3 ${style.title}`}>
                  {data?.description}
                </h3>
                {data?.url_link && <Link to={data?.url_link} className={`custom_btn text-light`}>
                  <span>{data?.url_text}</span> <span><Icon icon={'arrow'} color={'#fff'} size={14} /></span>
                </Link>}
              </div>

              {/* connect us */}
              <div className={`col-12 col-lg-5`}>
                <div className={style.connect_wrapper}>
                  <h3 className={`secondary-font ttl-4 ${style.title}`}>Connect Now</h3>
                  <div className={style.connect_link}>
                    {data?.facebook_url && <Link to={data?.facebook_url} className={style.icon}><span><Icon icon={'facebook'} color={'#fff'} size={18} /></span></Link>}
                    {data?.instagram_url && <Link to={data?.instagram_url} className={style.icon}><span><Icon icon={'instagram'} color={'#fff'} size={18} /></span></Link>}
                    {data?.product_url && <Link to={data?.product_url} className={style.icon}><span><Icon icon={'arrow-right'} color={'#fff'} size={14} /></span></Link>}
                    {data?.call_url && <a href={`tel:+91 62382 54535`} className={style.icon}><span><Icon icon={'Call'} color={'#fff'} size={18} /></span><span>+91 62382 54535</span></a>}
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>}
    </div>
  )
}

export default ContactSection