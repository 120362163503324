import { Button, Form } from 'react-bootstrap';
import style from './gallery.module.scss'
import { Formik } from 'formik';
import axios from 'axios';
import { BASE_URL } from '../../../Config/api';
const GalleryCreate = ({ setIsListing }) => {
    return (
        <div className={style.root}>


            <Formik
                initialValues={{
                    gallery_title: '',
                    gallery_image: '',

                }}
                validate={values => {
                    console.log(values.gallery_image);

                    const errors = {};
                    if (!values.gallery_title) {
                        errors.gallery_title = 'Required';
                    }
                    if (!values.gallery_image) {
                        errors.gallery_image = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {


                    const formData = new FormData();
                    formData.append('title', values.gallery_title);
                    formData.append('image', values.gallery_image);
                    formData.append('date', Date.now());
                    for (let index = 0; index < values.gallery_image.length; index++) {
                        formData.append('image', values.gallery_image[index]);
                    }

                    axios.post(`${BASE_URL}/gallery`, formData).then((response) => {
                        if (response.data.success) {

                            setIsListing(true)
                        }


                    });




                }}


            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                    /* and other goodies */
                }) => (
                    <Form onSubmit={handleSubmit} className={`row g-1 g-sm-2`}>
                        <Form.Group className="col-lg-6">
                            <Form.Label>Gallery Title</Form.Label>
                            <Form.Control
                                name="gallery_title"
                                type="text"
                                placeholder="Enter title name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.gallery_title}
                            />
                            <span className={`errors`}>{errors.gallery_title && errors.gallery_title}</span>
                        </Form.Group>




                        <Form.Group className="col-6">
                            <Form.Label>Gallery Image</Form.Label>
                            <Form.Control
                                type="file"
                                multiple={true}
                                placeholder="gallery file"
                                onChange={(e) => setFieldValue("gallery_image", e.target.files)}
                            />
                            <span className={`errors`}>{errors.gallery_image && errors.gallery_image}</span>
                        </Form.Group>
                        <small>Please upload an image that have 100x100 size, png jpg jpge is allowed</small>









                        <div className={`col-12 d-flex mt-4`}>
                            <Button variant="primary" type="submit" className='w-auto  px-3 py-2 text-white ms-auto'>
                                Submit Details +
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>



        </div>
    )
}

export default GalleryCreate