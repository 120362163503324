import axios from "axios";
import { useEffect, useState } from "react"
import { BASE_URL } from "../../../Config/api";

export const useHomePage = () => {
  const [isListing, setIsListing] = useState(true);
  const [data, setData] = useState(null);
  const [id, setId] = useState(null);
  useEffect(() => {
    axios.get(`${BASE_URL}/home-page`).then((response) => {
      setData(response.data);
    });
  }, []);


  return {
    isListing,
    setIsListing,
    data,
    setId,
    id
  }
}
