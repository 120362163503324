
import TitleHeading from "../TitleHeading"
import { useTitleHeading } from "../TitleHeading/useTitleHeading"
import TestimonialsCreate from "./testimonialsCreate"
import TestimonialsList from "./testimonialsList"


const Testimonials = () => {
  const {isListing, setIsListing} = useTitleHeading()
  const url_data = '/admin/testimonials'
  return (
    <div className={`w-100`}>
            <div className={` `}>
                <div className={`row`}>
                    <TitleHeading url={url_data} title={`Testimonials`} setIsListing={setIsListing} isListing={isListing}/>
                    
                    {
                        isListing ?  
                        <TestimonialsList handleView={setIsListing}/> :
                        <TestimonialsCreate handleView={setIsListing}/>
                    }
                   
                </div>
            </div>
        </div>
  )
}

export default Testimonials