import axios from "axios";
import { useEffect, useState } from "react"
import { BASE_URL } from "../../Config/api";

export const useProductList = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}/product`).then((response) => {
            setData(response.data);
        });
    }, []);




    
    return {
        data,
    }
}
