
import TitleHeading from "../TitleHeading"
import { useTitleHeading } from "../TitleHeading/useTitleHeading"
import CategoryCreate from "./categoryCreate";
import CategoryList from "./categoryList";

const Category = () => {
  const url_data = '/admin/category'
  const {isListing, setIsListing} = useTitleHeading();
  return (
    <div className={`w-100`}>
    <div className={` `}>
        <div className={`row`}>
            <TitleHeading title={`Category`} setIsListing={setIsListing} isListing={isListing} url={url_data}/>
            
            {
                !isListing ?  
                <CategoryList handleView={setIsListing}/> :
                <CategoryCreate handleView={setIsListing}/>
            }
           
        </div>
    </div>
</div>
  )
}

export default Category