import React from "react";
import style from "./productSimple.module.scss";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../Config/api";
function ProductSimple(props) {
  const { title, image, price } = props;
  const product_url = title.replace(/\s+/g, "-").toLowerCase();
  return (
    <Link className={style.root} to={`/product/${product_url}`}>
      {image && (
        <div className={`${style.image_wrapper}`}>
          <img
            loading="lazy"
            src={`${BASE_URL}/uploads/${image[0]?.filename}`}
            alt="banner"
          />
        </div>
      )}
      <div className={`${style.text_wrapper}`}>
        <div className={`${style.name}`}>
          {title && <h4 className={`ttl-2 mb-0`}>{title}</h4>}
        </div>
        <div className={`${style.detail_wrapper}`}>
          {/* <div className={`${style.clr_ft}`}>
            <h6 className={`ttl-2 mb-0`}>1ft</h6>
            <ul className={`${style.clr}`}>
              <li className={`${style.clr_icon}`}></li>
              <li className={`${style.clr_icon}`}></li>
              <li className={`${style.clr_icon}`}></li>
            </ul>
          </div> */}
          <div className={`${style.price_wrapper}`}>
            <h5 className={`ttl-2`}>₹{price}</h5>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ProductSimple;
