import axios from 'axios';
import { useEffect, useState } from 'react'
import { BASE_URL } from '../../../Config/api';

export const useWhatWeDo = () => {
  const [data, setData] = useState(null);
  const [product, setProduct] = useState(null);
  const [id, setId] = useState(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [productList, setProductList] = useState([]);
  const [counter, setCounter] = useState(1);

  const handleProduct = (id) => {
    const index = productList.findIndex(item => item.id === id);
    const isNotIdExist = productList[index].count === null;
    if (isNotIdExist) {
      const newData = [...productList];
      setCounter(counter + 1)
      const index = newData.findIndex(item => item.id === id);
      newData[index].count = counter
      setProductList(newData)
    }
    else {
      setCounter(counter - 1)
      const newData = [...productList];
      const filteredData = newData.filter(item => item.id === id);
      const countCurrent = filteredData[0]?.count;
      newData[index].count = null;
      newData.map((item, index) => {
        if (item.count > (countCurrent)) {
          newData[index].count = newData[index].count - 1
        }
        return null
      })

      setProductList(newData);
    }
    // if (!isIdExist) {
    //   const newData = [...productList, {id : id, count :productList.length + 1}]
    //   setProductList(newData)
    // }
    // else{
    //   const newData = [...productList];
    //   const filteredData = newData.filter(item => item.id !== id);
    //   filteredData.map((item, index) => item.count = (index + 1))
    //   setProductList(filteredData)
    // }

  }





  useEffect(() => {
    axios.get(`${BASE_URL}/what-we-do`).then((response) => {
      console.log(response);
      setData(response.data[0]);
      setId(response.data[0].id)
    });
  }, []);

  useEffect(() => {
    axios.get(`${BASE_URL}/product`).then((response) => {
      setProduct(response.data);
    });
  }, []);

  useEffect(() => {
    let productLists = []
    product && product.map((item, index) => productLists.push({ id: item.id, count: null }));
    setProductList(productLists);
  }, [product]);


  return {
    data,
    id,
    product,
    setIsModelOpen,
    isModelOpen,
    handleProduct,
    productList
  }
}
