import React from 'react'
import Header from '../Header'
const Dashboard = ({setData}) => {


    return (
        <div className={`w-100`}>
            Dashboard
            
        </div>
    )
}

export default Dashboard
