import React from "react";
import ContactSection from "../ContactSection";
import Gallery from "../Gallery";
import HeroSection from "../HeroSection";
import NewProducts from "../NewProducts";
import Testimonials from "../Testimonials";
import WhatWedo from "../WhatWedo";
import style from "./home.module.scss";
import { useHome } from "./useHome";
import Banner from "../Banner";

function Home() {
  const { data, newProduct, testimonials, featuredProduct, gallery } =
    useHome();
  const HOME_DATA = data?.home_page;
  const bannerData = HOME_DATA?.filter((item) => item?.type === "home_banner");
  const mediaData = HOME_DATA?.filter((item) => item?.type === "home_media");
  const whatWeDoData = HOME_DATA?.filter(
    (item) => item?.type === "home_whatWeDo"
  );
  const testimonialsData = testimonials;
  const whatWeDoProducts = data?.what_we_do_products;
  const featuredProducts = featuredProduct;

  return (
    <div className={style.root}>
      {bannerData ? (
        <>
          <HeroSection bannerData={bannerData} featured={featuredProducts} />
          <NewProducts productData={newProduct} />
          <ContactSection mediaData={mediaData} />
          <Gallery galleryData={gallery} />
          <WhatWedo
            whatWeDoData={whatWeDoData}
            whatWeDoProducts={whatWeDoProducts}
          />
          <Testimonials data={testimonialsData} />
        </>
      ) : (
        <div className={`${style.shimmer_wrapper_homer}`}>
          <div className={`container`}>
            <div className={`row g-1 g-sm-2`}>
              <div className={`col-12 col-lg-9`}>
                <div className={`${style.banner} shimmer-container`}>
                  <div className="shimmer-element"></div>
                </div>
              </div>
              <div className={`col-12 col-lg-3`}>
                <div className={`${style.main_product} shimmer-container`}>
                  <div className="shimmer-element"></div>
                </div>
              </div>
            </div>

            <div className={`${style.content_wrapper}`}>
              <div className={`${style.title} shimmer-container`}>
                <div className="shimmer-element"></div>
              </div>
            </div>

            <div className={`row g-1 g-sm-2`}>
              <div className={`col-6 col-lg-3`}>
                <div className={`${style.main_product_sub} shimmer-container`}>
                  <div className="shimmer-element"></div>
                </div>
              </div>
              <div className={`col-6 col-lg-3`}>
                <div className={`${style.main_product_sub} shimmer-container`}>
                  <div className="shimmer-element"></div>
                </div>
              </div>
              <div className={`col-6 col-lg-3`}>
                <div className={`${style.main_product_sub} shimmer-container`}>
                  <div className="shimmer-element"></div>
                </div>
              </div>
              <div className={`col-6 col-lg-3`}>
                <div className={`${style.main_product_sub} shimmer-container`}>
                  <div className="shimmer-element"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {MainData && <MainData/>} */}

      {/* {data_out} */}
    </div>
  );
}

export default Home;
