import { SketchPicker } from 'react-color';
import { useProducts } from './useProducts';
import style from './colorSelector.module.scss'
import { useEffect } from 'react';
const ColorSelector = ({ value, handleChange }) => {
    const {
        setIsColorPaletteOpen,
        isColorPaletteOpen,
        color,
        setColor,
        handleColorArray,
        colorArray: cArray,
        updateColorArray,
        setColorArrayIndex,
        colorArrayIndex: cI,
        handleRemoveColor,
        setColorText,
        handleTextChange
    } = useProducts()
    


    const handleCreateColor = () => {
        setColorArrayIndex(null)
        setIsColorPaletteOpen(true)
        handleColorArray()
    }

    const handleChangeColor = (e) => {
        setColor(e.hex)
        updateColorArray(cI !== null ? cI : cArray.length - 1, e.hex);
    }

    const handleUpdateColor = (index) => {
        setColorArrayIndex(index);
        setIsColorPaletteOpen(true)
        setColor(cArray[index].color)
    }

    

    useEffect(() => {
        
        color && handleChange("product_color", cArray)
    }, [cArray, handleChange, color]);


    // handleColorArray({color:e.hex})
    return (


        <div className={style.root}>
            <div className={style.color_wrapper}>    
                {cArray && cArray.map((item, index) => {
                    return (
                        <div onClick={() => handleUpdateColor(index)} className={`${cI === index ? 'active' : cI === null && (cArray.length - 1 === index) ? 'active' : ''} ${style.color_palette}`}>
                            <div className={`${style.color}`} style={{ backgroundColor: item.color }}></div>
                           <input type="text" placeholder='Enter color name' name="" id="" value={item.textColor} onChange={(e) => handleTextChange(e, index)} />
                           <button type='button' className={style.removeColor} onClick={() => handleRemoveColor(index)}></button>
                        </div>
                    )
                })}
                <div>
                    <button onClick={handleCreateColor} className={style.add_color} type='button'>
                        add color  +
                    </button>
                    {isColorPaletteOpen &&
                        <div className={style.popup}>
                            <button type='button' onClick={() => { setIsColorPaletteOpen(false); setColorArrayIndex(null) }}></button>
                            <SketchPicker color={color || `#ffffff`} onChange={handleChangeColor} />
                        </div>
                    }
                </div>
            </div>
            




           
        </div>
    )
}

export default ColorSelector