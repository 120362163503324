import './App.css';
import './Assets/Style/app.scss'
import AppRoute from './AppRoute';
import ScrollToTop from './components/ScrollToTop/scrollTotop';

function App() {


  return (
    <AppRoute />
  );
}

export default App;
